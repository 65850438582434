import { UserBlocked, UserBlockedDto } from '@app/store/models';
import { CustomerDto } from './index';

export class Customer {
    no: string;
    name: string;
    secondName: string;
    balance: string;
    balanceDue: string;
    creditLimitLcy: string;
    salesPersonCode: string;
    locationCode: string;
    blocked: UserBlocked | null;
    vatRegistrationNo: string;
    postCode: string;
    city: string;
    searchName: string;
    addresses: string;
    paymentTerm: string;
    paymentMethod: string;
    purchaserCode: string;
    hasUserInPortal: boolean;

    constructor(dto: CustomerDto) {
        this.no = dto.no ?? '';
        this.name = dto.name ?? '';
        this.secondName = dto.name2 ?? '';
        this.balance = dto.balance ?? '';
        this.balanceDue = dto.balance_due ?? '';
        this.creditLimitLcy = dto.credit_limit_lcy ?? '';
        this.salesPersonCode = dto.sales_person_code ?? '';
        this.locationCode = dto.location_code ?? '';
        this.blocked = dto.blocked ? this.getBlockedValue(dto.blocked) : null;
        this.vatRegistrationNo = dto.vat_registration_no ?? '';
        this.postCode = dto.post_code ?? '';
        this.city = dto.city ?? '';
        this.searchName = dto.search_name ?? '';
        this.addresses = dto.addresses ?? '';
        this.paymentTerm = dto.payment_terms ?? '';
        this.paymentMethod = dto.payment_method ?? '';
        this.purchaserCode = dto.purchaser_code ?? '';
        this.hasUserInPortal = dto.has_user_in_portal ?? false;
    }

    getBlockedValue(blocked: UserBlockedDto) {
        switch (blocked) {
            case 'All':
                return 'All';
            case 'Ship':
                return 'Ship';
            case 'Invoice':
                return 'Invoice';
            default:
                return null;
        }
    }
}
