import { createReducer, on } from '@ngrx/store';
import { customersActions } from '../actions/customers.actions';
import { Customer } from '../models';

export interface CustomersState {
    loaded: boolean | null;
    customers: Customer[] | null;
    totalItems: number | null;
}

export const initialState: CustomersState = {
    loaded: null,
    customers: null,
    totalItems: null,
};

export const customersReducer = createReducer(
    initialState,
    on(
        customersActions.getCustomers,
        (state): CustomersState => ({
            ...state,
            loaded: null,
        }),
    ),
    on(
        customersActions.getCustomersSuccess,
        (state, payload): CustomersState => ({
            ...state,
            loaded: true,
            customers: payload.items,
            totalItems: payload.totalItems,
        }),
    ),
    on(
        customersActions.getCustomersError,
        (state): CustomersState => ({
            ...state,
            loaded: false,
        }),
    ),
    on(
        customersActions.clear,
        (): CustomersState => ({
            ...initialState,
        }),
    ),
);
